.DownloadLink {
  z-index: 1;

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100dvh;
      background: rgba(0, 0, 0, 0.8);
    }

    .modal-container {
      z-index: 1;
      background-color: white;
      padding: 20px;
      border-radius: 5px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;
      max-width: 400px;
      animation: fadeIn 0.3s ease-in-out;

     .modal-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        svg {
          path {
            fill: $black;
          }
        }

        h2 {
          flex: 1;
          font-size: 20px;
        }

        .pictoButton {
          background: none;
          padding: 6px;
          svg {
            path {
              stroke: $black;
            }
          }
        }
      }

      .modal-content > a {
        display: flex;
        flex-direction: column;
        gap: 20px;
        pointer-events: none;

        .footer {
          display: flex;
          align-items: center;
          justify-content: center;

          .buttons {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 20px;

            .Button {
              pointer-events: auto;
              border-radius: 5px;
              padding: 10px 24px;
              background: $travelPlanerButtonBackground;
              color: $travelPlanerButtonColor;
              border: 1px solid $travelPlanerButtonBackground;
              transition: all 0.2s ease-in-out;

              &:hover {
                background: $travelPlanerButtonBackground;
                color: $travelPlanerButtonColor;
                border: 1px solid $travelPlanerButtonBackground;
                opacity: 0.9;
              }
            }
          }
        }
      }
    }
    
  }
  .pictoButton.copy {
    svg {
      path {
        stroke: none!important;
      }
    }
  }
}