.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 80px);
  margin-top: 80px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &__title {
    font-size: 2rem;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding:0 10%;
    width: 100%;
    border-radius: 8px;

    &__text {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .error-subtitle {
        font-size: 1.5rem;
      }

      .error-message {
        font-size: 1rem;
      }

      .error-buttons {
        display: flex;
        flex-flow: row wrap;
        gap: 1rem;

        &__button, &__button-secondary {
          border: none;
          min-width: 120px;
          padding: .8rem 1rem;
          font-size: 1rem;
          cursor: pointer;
          transition: opacity 0.3s ease;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }

    &__image {
      display: flex;
      justify-content: center;
      align-items: center;
      max-height: 400px;
    }
  }



}


@media all and (max-width: 768px){
  .error-container {

    &__title {
      margin-bottom: 1rem;
      font-size: 1.5rem;
    }

    &__image {
      width: 70%;
      max-height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__content {
      flex-flow: row wrap;

      .error-subtitle{
        display: none;
      }

      .error-message {
        margin-bottom: 1rem;
      }
    }

    .error-buttons {
      justify-content: center;
    }
  }
}
