.GroupsShopV2__items {
  $mobileGap: 15px;
  $desktopGap: 20px;

  display: flex;
  flex-direction: column;
  gap: $mobileGap;

  @media screen and (min-width: 768px) {
    gap: $desktopGap;
  }
}