.agent {
  &.withLabel {
    .floatingButton {
      padding: 2px 12px;
    }
  }

  .floatingButton {
    position: fixed;
    right: 18px;
    bottom: 11px;
    background: $pro;
    height: 50px;
    padding: 2px 8px;
    border-radius: 30px;
    z-index: 1;
    border: solid 1px $white;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 60%;
      path {fill: $white;}
      width: 32px;
    }

    span {
      line-height: 1;
      font-weight: 600;
      color: $white;
    }
  }
  .highLight{
    position: fixed;
    bottom: 60px;
    margin: 18px;
    background: $white;
    right: 0;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
    transition: opacity linear 150ms;
    padding: 16px 8px;
    border-radius: 16px;
    max-width: 400px;
    opacity: 0;

    &.visible {
      opacity: 1;
    }
  }

  .agentBlock {
    position: fixed;
    background: $black25;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: flex-end;
    z-index: 10000;
    display: flex;
    opacity: 0;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
    overflow: hidden;
    transition: opacity linear 150ms;
    height: 0;
    width: 0;

    &.visible {
      opacity: 1;
      height: initial;
      width: initial;
    }

    .chatIframe {
      position: relative;
      height: 95%;
      width: 100%;
      overflow-y: hidden;
      background: $white;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }
    .close {
      position: absolute;
      top: calc(5% + 10px);
      right: 10px;
      height: 30px;
      width: 30px;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media screen and (min-width: 600px) {
      top: initial;
      left: initial;
      bottom: 70px;
      right: 18px;
      border-radius: 16px;

      &.visible {
        height: calc(75% - 70px);
        max-width: 40%;
        min-width: 400px;
        width: 30%;
      }

      .chatIframe {
        height: 100%;
      }
      .close {
        top: 10px;
      }
    }

    @media screen and (min-width: 1920px) {
      &.visible {
        width: 25%;
      }
    }
  }

}