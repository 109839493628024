.NewsletterV2 {
    $padding: 70px 20px;
    $mobileGap: 20px;
    $desktopGap: 30px;
  
    background: $green;
    padding: $padding;
    font-family: $mainFont;
    display: flex;
    align-items: center;
    justify-content: center;
  
    .NewsletterV2__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: $mobileGap;
      justify-content: center;
  
      .NewsletterV2__header {
        display: flex;
        flex-direction: column;
        gap: 10px;
  
        .NewsletterV2__header__hat {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
  
          .NewsletterV2__header__hat__circle {
            width: 20px;
            aspect-ratio: 1;
            border-radius: 100%;
            background: $white;
          }
  
          .NewsletterV2__header__hat__text {
            color: $white;
            font-size: 13px;
            line-height: 20px;
            font-weight: 700;
            letter-spacing: 2%;
            text-align: center;
          }
        }
  
        .NewsletterV2__header__title {
          font-size: 24px;
          font-weight: 700;
          line-height: 32px;
          text-align: center;
        }
      }
  
      .NewsletterV2__inputContainer {
        width: 100%;
        position: relative;
  
        .NewsletterV2__input {
          width: 100%;
          border-radius: 23px;
          background: $white;
          border: 1px solid #21212B33;
          padding: 13px 90px 13px 15px;
          font-size: 13px;
          font-weight: 500;
          line-height: 20px;
        }
  
        .NewsletterV2__subButton {
          position: absolute;
          top: 50%;
          translate: 0 -50%;
          right: 15px;
          font-size: 13px;
          font-weight: 700;
          line-height: 20px;
          background: $white;
        }
      }
    }
  
    @media screen and (min-width: 768px) {
      .NewsletterV2__container {
        width: 100%;
        max-width: 660px;
        gap: $desktopGap;
  
        .Checkbox {
          max-width: 522px;
        }
      }
    }
  }