.ImagesGroup {
  width: 100%;
  display: grid;
  gap: 20px;

  &__copyright {
    bottom: 20px;

    .copyright-symbol {
      width: 30px;

      // @media (min-width: 1024px) {
      //   width: 0px;
      // }
    }
  }

  &--1 {
    grid-template-columns: 1fr;
  }

  &--2 {
    grid-template-columns: 1fr 1fr;

    &.ImagesGroup--large {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;

      .ImagesGroup__imageContainer {
        aspect-ratio: 320 / 220;
      }
    }

    .ImagesGroup__imageContainer {
      aspect-ratio: 150 / 220;
    }
  }

  &--3 {
    aspect-ratio: 320 / 425;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);

    .ImagesGroup__imageContainer {
      &:nth-child(1) {
        grid-area: 1 / 1 / 5 / 7;
      }

      &:nth-child(2) {
        grid-area: 5 / 1 / 7 / 5;
      }

      &:nth-child(3) {
        grid-area: 5 / 5 / 7 / 7;
      }

    }
  }

  .ImagesGroup__imageContainer {
    &:hover {
      cursor: pointer;
    }

    .ImagesGroup__imageContainer__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 3px;
    }
  }

  @media screen and (min-width: 768px) {
    &--2 {
      &.ImagesGroup--large {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;

        .ImagesGroup__imageContainer {
          aspect-ratio: 433 / 320;
        }
      }

      .ImagesGroup__imageContainer {
        aspect-ratio: 320 / 450;
      }
    }

    &--3 {
      aspect-ratio: 400 / 200;
      max-width: 100%;
      display: flex;
      align-items: stretch;
      justify-content: center;

      .ImagesGroup__imageContainer {
        grid-area: initial !important;
        flex: 1;
      }
    }
  }
}
