.CategoriesFilters {
  .categories {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 6px;
    overflow-x: auto;

    .category {
      padding: 10px 15px;
      background: $white;
      color: $travelPlanerSecondaryColor;
      border: 1px solid $travelPlanerSecondaryColor;
      border-radius: 100px;
      font: 500 12px/14px $travelPlanerTitleFont;
      white-space: nowrap;
      text-transform: uppercase;
      transition: all 0.2s ease-in-out;

      &.selected {
        background: $travelPlanerSecondaryColor;
        color: $white;
        border-color: $travelPlanerSecondaryColor;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .categories {
      padding-bottom: 10px;

      &::-webkit-scrollbar {
        display: block;
      }
    }
  }
}