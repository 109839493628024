@import './styles/base/_colors.scss';
@import './styles/base/_fonts.scss';
@import './styles/base/_icons.scss';
@import './styles/base/_layout.scss';
@import './styles/base/_utilities.scss';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.SSRApp {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.Article__content {
  $mobileHorizontalPadding: 20px;
  $tabletHorizontalPadding: 10vw;
  $desktopHorizontalPadding: 20vw;

  $mobileArticlePadding: 50px $mobileHorizontalPadding 70px $mobileHorizontalPadding;
  $tabletArticlePadding: 50px $tabletHorizontalPadding 80px $tabletHorizontalPadding;
  $desktopArticlePadding: 50px $desktopHorizontalPadding 80px $desktopHorizontalPadding;

  padding: $mobileArticlePadding;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .Block {
    h4 {
      margin: 5px 0 10px 0;
    }

    h5 {
      margin: 5px 0;
    }
  }

  .ImagesGroup {
    margin: 20px 0;
  }

  .ArticlesCarousel,
  .ScrollingEngineSuggestions,
  .SearchAround {
    margin-left: -$mobileHorizontalPadding;
    margin-right: -$mobileHorizontalPadding;
  }

  .ButtonV2 {
    margin: 0 auto;
  }

  @media screen and (min-width: 768px) {
    padding: $tabletArticlePadding;
    gap: 15px;

    .Block {
      padding: 0 10%;
    }

    .ImagesGroup--normal {
      padding: 0 10%;
    }

    .ArticlesCarousel,
    .ScrollingEngineSuggestions,
    .SearchAround {
      margin-left: -$tabletHorizontalPadding;
      margin-right: -$tabletHorizontalPadding;
    }
  }

  @media screen and (min-width: 1200px) {
    padding: $desktopArticlePadding;

    .ArticlesCarousel,
    .ScrollingEngineSuggestions,
    .SearchAround {
      margin-left: -$desktopHorizontalPadding;
      margin-right: -$desktopHorizontalPadding;
    }
  }
}

body:has(.fixedBlock) {
  overflow: hidden;
}

.slideshow-img {
  object-fit: contain;
}

@import './components/AccessibilityIcons';
@import './components/ArticleHero';
@import './components/Block';
@import './components/Button';
@import './components/Buttons/Share';
@import './components/ButtonV2';
@import './components/AppPopup';
@import './components/Carousel';
@import './components/Carousel/ArticlesCarousel/Header';
@import './components/Carousel/ArticlesCarousel/ArticleCard';
@import './components/Carousel/ArticlesCarousel/Label';
@import './components/Carousel/ArticlesCarousel/Link';
@import './components/Carousel/ArticlesCarousel';
@import './components/ChatAgent';
@import './components/ClientSideSlideshowLightbox';
@import './components/Confetti';
@import './components/DefaultCarousel';
@import './components/EditoPage';
@import './components/Footer';
@import './components/FloatingSearchBar';
@import './components/Header';
@import './components/Heros/HeroLanding';
@import './components/Heros/HeroResult';
@import './components/Heros/HeroSearch';
@import './components/Heros/HeroPerso';
@import './components/Heros/HeroSuggestions';
@import './components/MiniCarousel';
@import './components/BannerImage';
@import './components/BannerWithTextImage';
@import './components/ImagesGroup';
@import './components/LightSearchGrid';
@import './components/PartnerList';
@import './components/Picto';
@import './components/Modal';
@import './components/Newsletter';
@import './components/NewsletterV2';
@import './components/Popover';
@import './components/SearchBar';
@import './components/ResultGrid';
@import './components/Shop';
@import './components/ShopV2/Container';
@import './components/ShopV2/Description';
@import './components/ShopV2/Detail';
@import './components/ShopV2/Groups/Details';
@import './components/ShopV2/Groups/Table';
@import './components/ShopV2/Groups/Text';
@import './components/ShopV2/Groups/TextList';
@import './components/ShopV2/Groups';
@import './components/ShopV2/Hero';
@import './components/ShopV2/Labels';
@import './components/ShopV2/MainInfos';
@import './components/ShopV2/Management';
@import './components/ShopV2';
@import './components/ShopV2Card';
@import './components/StickyItem';
@import './components/ListCategory';
@import './components/ImageCarouselPopup';
@import './components/ImagesCarousel';
@import './components/ImagesGalleryCarousel';
@import './components/ResponsiveImage';
@import './components/404';
@import './components/Portrait';
@import './components/Radio';
@import './components/Quote';
@import './components/Select';
@import './components/Separator';
@import './components/SharingLinks';
@import './components/StarNote';
@import './components/TilesBlock';
@import './components/Meteo';
@import './components/TravelPlaner';
@import './components/TravelPlaner/inputs/Number';
@import './components/TravelPlanerHub';
@import './components/TravelPlanerResults';
@import './components/TravelPlanerResults/index';
@import './components/TravelPlanerResults/PDF/styles/DownloadLink';
@import './components/TravelPlanerResults/TravelDays';
@import './components/LoginPopup';
@import './components/OAuthButton';
@import './components/TopPageNotice';
@import './components/ContentShowcase';
@import './components/ContactForm';
@import './components/InteractiveContent';
@import './components/InteractiveMap';
@import './components/InteractiveBanner';
@import './components/FlexContent';
@import './components/FlexContainer';
@import './components/ImageContent';
@import './components/CustomImage';
@import './components/Toggle';
@import './components/BannerWithTextImage';
@import './components/TravelPlanerError';
@import './components/Navigation';
@import './components/eventsSection';
@import './features/auth/components/AccountPanel';
@import './features/auth/components/GoogleLoginButton';
@import './features/auth/components/PageHeader';
@import './features/auth/components/SSOAuth';
@import './features/auth/pages/auth/Account';
@import './features/auth/pages/auth/Favorites';
@import './features/auth/pages/auth/Newsletter';
@import './features/auth/pages/guest/ForgotPassword';
@import './features/auth/pages/guest/Login';
@import './features/auth/pages/guest/Signup';
@import './features/auth/stacks/Auth';
@import './features/auth/stacks/Guest';
@import './features/favorites/components/AddToFavoritesModal';
@import './features/favorites/components/Button';
@import './features/favorites/components/Card/Engine';
@import './features/favorites/components/Card';
@import './features/favorites/components/EditGroup';
@import './features/favorites/components/Group';
@import './features/favorites/components/NewGroupButton';
@import './features/favorites/components/NewGroupModal';
@import './features/favorites/components/RenameGroupModal';
@import './components/Checkbox';
@import './components/Form';
@import './components/Input';
@import './components/SearchAround';
@import './components/Engine';
@import './components/EnginePage';
@import './components/ContactForm';
@import './components/FloatingSearchBar';
@import './components/SocialBanner';
@import './components/Copyright';
@import './components/Video';
@import './components/ArticleLinkButton';
@import './components/ScrollingEngineSuggestions';
