.ShopV2 {
  $headerHeight: 70px;

  text-align: left;

  .ShopV2__top__container {
    .ShopV2__container {
      .ShopV2__content {
        $contentPadding: 20px 20px 50px 20px;
        $contentGap: 20px;

        padding: $contentPadding;
        display: flex;
        flex-direction: column;
        gap: $contentGap;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .ShopV2__top__container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(1, 1fr);

      .HeroShopV2 {
        grid-area: 1 / 1 / 2 / 3;
        z-index: 1;
        max-width: 360px;
        border-radius: 3px;
        overflow: hidden;
        position: sticky;
        margin-top: 80px;
        margin-bottom: 160px;
        top: calc($headerHeight + 50px);
        left: 50px;
        height: fit-content;
      }

      .ShopV2__container {
        grid-area: 1 / 1 / 2 / 3;
        $leftPadding: 410px; // 480px
        $rightPadding: 40px; // 140px

        .DescriptionShopV2 {
          padding-left: calc($leftPadding + 8%);
          padding-right: calc($rightPadding + 8%);
        }

        .ShopV2__content {
          padding: 50px calc($rightPadding + 5%) 80px calc($leftPadding + 5%);

          .ShopV2__gallery {
            padding: 0;

            .ContainerShopV2__title {
              padding: 30px 30px 0 30px;
            }

            .ImagesGalleryCarousel__carousel {
              padding: 0 0 30px 0;

              &__slide {
                &:nth-child(1),
                &:nth-child(2) {
                  padding-left: 30px;
                }

                &:nth-last-child(1) {
                  padding-right: 30px;
                }
              }

              &--1 {
                .ImagesGalleryCarousel__carousel__slide {
                  padding-right: 0;
                }
              }

              &__pagination {
                padding: 0 30px;
              }

              &__navigationPrev {
                left: 25px;
              }

              &__navigationNext {
                right: 25px;
              }
            }
          }
        }
      }
    }
  }
}

.shop-title-container {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 20px;

    h1 {
        margin: 0;
    }
}
