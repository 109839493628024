@use "sass:map";

.TripLengthSelector {
  width: 100%;
  position: relative;

  &.open {
    .selectBlock {
      svg:last-child {
        rotate: 180deg;
      }
    }

    .options {
      border: 1px solid $gray100;
      max-height: 200px;
    }
  }

  .selectBlock {
    width: 100%;
    height: 100%;
    padding: 18px 24px;
    display: flex;
    align-items: center;
    gap: 12px;
    border: 1px solid $gray100;
    border-radius: 5px;
    background: $white;
    overflow: hidden;

    svg:first-child {
      width: 14px;
      min-width: 14px;
      height: auto;
    }

    span {
      display: block;
      font: 500 12px/1 $travelPlanerTitleFont;
      flex: 1;
      text-align: left;
      min-width: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    svg:last-child {
      min-width: 8px;
      transition: rotate 0.2s;
    }
  }

  .options {
    z-index: 100;
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    width: 100%;
    background: $white;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    max-height: 0;
    transition: max-height 0.2s;

    button {
      width: 100%;
      padding: 8px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      opacity: 0.7;
      text-align: left;
      font: 500 12px/1 $travelPlanerTitleFont;
      transition: opacity 0.2s;

      &:hover,
      &:focus {
        opacity: 1;
        background: $gray100;

        svg path {
          fill: $pro;
        }
      }

      .tag {
        display: flex;
        align-items: center;
        gap: 4px;
        background: red;
        border-radius: 30px;
        padding: 4px 8px;
        font-weight: bold;
        font-size: 12px;

        svg {
          min-height: 12px;
          min-width: 12px;
        }

        @each $type, $content in $searchColors {
          &.#{$type} {
            background: map.get($content, background);
            color: map.get($content, color);

            svg path {
              fill: map.get($content, color);
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
  }
}
