.FiltersAndSort {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 12px;

  .filtersSortContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 12px;

    .sortContainer {
      display: flex;
      align-items: center;
      gap: 8px;

      span {
        white-space: nowrap;
        font: 400 12px/14px $travelPlanerTitleFont;
      }

      select {
        cursor: pointer;
        font: 500 12px/14px $travelPlanerTitleFont;
      }
    }

    .localGuides {
      display: flex;
      align-items: center;

      input {
        cursor: pointer;
      }

      label {
        padding-left: 6px;
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
        font: 500 12px/14px $travelPlanerTitleFont;
        color: $green;

        svg {
          width: 10px;
          height: 10px;

          path {
            fill: $green;
            stroke: $green;
          }
        }
      }
    }
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: #E5E5E5;
  }

  @media screen and (min-width: 768px) {
  }
}