.shop {
  .flex {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .hero {
    position: relative;
    height: 310px;
    width: 100%;
    display: flex;
    align-items: flex-end;

    .background {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      height: 100%;
      width: 100%;
      background: $client;
    }

    .shop_header_info {
      margin: 10px 5%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: space-evenly;
      color: $white;
      width: 100%;
      z-index: 2;
    }

    .category {
      color: $white;
      font-family: $mainFont;
      font-size: 13px;
      font-weight: 500;
      text-transform: uppercase;
      padding: 0 12px 0 0;
      height: 24px;

      svg {
        height: 15px;
        max-width: 30px;

        path {
          stroke: transparent;
          fill: $white;
        }
      }
    }

    .name {
      color: $white;
      font-family: $mainFont;
      font-weight: bold;
      padding: 8px 0 3px;
      font-size: 29.4px;
      line-height: 30px;
      /* or 102% */
      letter-spacing: 0.7px;
    }

    .star {
      height: 15px;
      width: 15px;
    }

    .location {
      margin-top: 2px;
      svg {
        margin-right: 5px;
        width: 9px;
      }
    }

    .location, .category {
      path, circle {
        stroke: $white
      }
    }

    .meteo {
      bottom: 93px;
      margin: 0 5%;
    }

    &.noCoupon .meteo {
      bottom: 35px;
    }

    @media screen and (max-width: 768px) {
      &.withMeteo {
        height: 310px;

        .shop_header_info {
          margin-bottom: 80px;
        }

        .meteo {
          position: absolute;
          right: 0;
          left: 0;
          bottom:35px;
        }
      }
    }
  }

  .content {
    // TODO grid ou flex
    margin: 23px 5%;
  }

  .breadcrumb {
    color: $gray500;
    display: flex;
    font-size: 13px;
    align-items: center;
    margin-bottom: 30px;

    svg {
      height: 9px;
    }

    path {
      stroke: $gray500;
    }
  }

  .description {
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    &.expanded {
      -webkit-line-clamp: unset;
    }
  }

  .caret {
    width: 0;
    height: 0;
    vertical-align: middle;
    border-top: 8px solid;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    margin: 10px auto 40px auto;
    transition: transform 0.2s ease-in-out;

    &.expanded {
      transform: rotate(180deg);
    }
  }

  .images-grid-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .main {
      width: 100%;
      height: 100%;
    }

    .secondary {
      margin-top: 20px;
      height: 230px;
      width: calc(50% - 10px);
    }

    .main img, .secondary img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .sideBlock {
  }

  .advantages {
    display: flex;
    flex-flow: column;
    justify-content: center;
    background-color: $client;
    color: $white;
    padding: 15px;
    margin: 35px 0;
    gap: 10px;
    border-radius: 25px;

    &.mobile { display: flex; }
    &.desktop { display: none; }
    @media screen and (min-width: 760px) {
      &.mobile { display: none; }
      &.desktop { display: flex; }
    }

    .advantage {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      flex-direction: column;
      text-align: left;
      gap: 0;
      padding: 12px;
      border: none;
      border-radius: 10px;
      overflow: hidden;

      svg {
        position: absolute;
        left: 5px;
      }
      .advantage_title {
        color: $yellow;
        margin: 0 0 9px 0;
        font-size: 16px;
        font-weight: 600;
      }
      .add {
        margin: 5px -12px -12px;
        padding: 4px;
        background: $black25;
        width: calc(100% + 24px);
        text-align: center;
      }
    }

    .advantages_title {
      font-size: 25px;
      font-weight: 700;
    }

    .advantages_button {
      background-color: $white;
      color: $client;
      border-radius: 22px;
      font-size: 12px;
      font-weight: 600;
      padding: 10px 30px;
      margin: 30px auto 10px;
      max-width: 240px;
    }
  }
  .confirmationPopup {
    .popupBlock {
      width: 650px;

      .title {
        text-align: center;
        font-size: 14px;
        padding-bottom: 32px;
        margin-bottom: 32px;
        border-bottom: solid 1px $gray300;

        &>div {
          font-weight: 800;
          font-size: 24px;
          margin: 0 32px 16px;
          color: $client;
        }
      }
      .content {
        width: initial;
        min-width: initial;
        margin: initial;
        &>span {
          color: $client;
          text-transform: uppercase;
          font-size: 12px;
        }
        .list {
          display: flex;
          flex-wrap: wrap;
          margin-top: 16px;
          gap: 24px;
          justify-content: space-between;

          &>div {
            font-size: 12px;
            line-height: 14px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            .number {
              height: 23px;
              width: 34px;
              overflow: hidden;
              position: relative;
              z-index: 1;
              padding: 5px 0 0;
              color: $white;
              font-size: 19px;
              font-weight: 800;
              &::after {
                content:"";
                position: absolute;
                background: $client;
                height: 31px;
                width: 34px;
                top: 0;
                left: 0;
                z-index: -1;
                border-radius: 30px;
              }
            }
            .logo svg {
              max-height: 30px;
              @media screen and (min-width: 768px) {max-height: 70px;}
            }
            .ltitle {
              color: $client;
              text-transform: uppercase;
              margin: 5px 0 8px;
              font-size: 10px;
            }
            &>svg {
              position: absolute;
              top: -10px;
              right: 40%;
              @media screen and (min-width: 768px){ right: 10px; top: 0; }
              path {
                stroke: $client;
              }
            }

            @media screen and (min-width: 768px){
              width: 160px;
            }
          }
          .vseparator {
            width: 50px;
            height: 1px;
            background: $gray300;
            margin: auto;

            @media screen and (min-width: 768px){
              width: 1px;
              height: 50px;
            }
          }
        }
      }
      .buttons {
        margin-top: 32px;
        gap: 16px;
        display: flex;
        justify-content: center;
      }
    }
  }

  .giftCardBlock {
    margin-top: 100px;
    white-space: pre-line;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 25px;
      line-height: 29px;
      font-weight: 700;
      color: $client;
      margin-bottom: 15px;

      svg {
        margin-top: -40px;
        .card {fill: $client;}
        .string {fill: $white;}
        .text {fill: $white;}
      }

    }
    .description {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      margin-bottom: 20px;

      span {font-weight: 600}
    }
  }

  .advantages .giftCardBlock {
    margin: 20px;

    .title {
      color: $white;

      svg {
        .card {fill: $white;}
        .string {fill: $client;}
        .text {fill: $client;}
      }
      .svgText {color: $client;}
    }
  }

  .advantages .sponsoBlock {
    margin: 20px;

    .title {
      color: $white;

      svg {
        .card {fill: $white;}
        .string {fill: $client;}
        .text {fill: $client;}
      }
      .svgText {color: $client;}
    }
  }

  .contact {
    .title {
      font-size: 25px;
      font-weight: 700;
      margin: 10px 0 5px;
    }

    .contact-element {
      display: flex;
      align-items: center;
      justify-content: start;
      margin: 15px 0;
      font-weight: 300;
      line-height: 18px;

      svg {
        margin-right: 20px;

        * {
          stroke: $client;
        }
      }

      .price-level {
        display: flex;
        align-items: center;

        svg {
          margin-right: -5px;

          path {
            stroke: none;
            fill: $black;
          }

          rect {
            stroke: $black;
          }
        }
      }
    }

    .Button {
      margin-top: 5px;
    }
  }

  .label {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px;
    border: solid 1px $client;
    margin: 40px 0 0;

    img {
      height: 30px;
      width: 30px;
      object-fit: contain;
    }

    .text {
      margin-left: 15px;
      font-size: 12px;
      font-weight: 700;
      color: $client;
      line-height: 20px;
      text-transform: uppercase;

      br {display: none;}
    }
  }

  .mapBlock {
    .title {
      font-size: 25px;
      font-weight: 700;
      margin: 30px 0 15px;
    }

    .map {
      width: 100%;
      height: 300px;
    }

    .address {
      font-size: 12px;
      margin: 10px 0 5px;
    }
  }

  .reviews {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      width: 100%;
      font-size: 25px;
      font-weight: 700;
      margin: 30px 0 15px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      &>span {
        flex: 1;
        min-width: 300px;
        margin: 5px 0;
      }

      &>div {
        display: flex;
        justify-content: space-between;
        flex: 1;
        min-width: 250px;
        margin: 5px 0;
      }
    }

    .star {
      height: 15px;
      width: 15px;
    }

    .flex-review {
      width: 100%;
      display: flex;
      flex-flow: column;
      justify-content: center;
      padding: 30px 45px;
      margin: 15px 0;
      background-color: #fafafa;
    }

    .flex-review-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      font-weight: 600;
      font-size: 12px;

      span{
        font-weight: 400;
        color: $gray300;
      }
    }

    .text {
      font-weight: 400;
      font-size: 12px;
      line-height: 25px;
      margin-top: 20px;
    }

    .reviewForm {
      width: 100%;

      form {
        .name, .note, .feedback {
          display: flex;
          align-items: center;
          margin: 10px 0;

          span {
            width: 200px;
          }
        }

        .name input {
          border: solid 1px $gray500;
          width: 250px;
          padding: 5px;
          border-radius: 10px;
        }
        .feedback textarea {
          width: 100%;
          height: 100px;
          padding: 5px;
          border-radius: 10px;
          border: solid 1px $gray500;
        }
      }
      .star {
        height: 20px;
        width: 20px;
      }
    }
  }

  @media screen and (min-width: 760px) {
    .hero {
      .name {
        max-width: 65%;
      }
    }
    .content {
      width: 55%;
      min-width: calc(85% - 350px);
    }
    .sideBlock {
      position: absolute;
      width: 30%;
      top: 370px;
      right: 5%;
      max-width: 350px;
      text-align: left;

      p{
        font-size: 1em;
      }

      .advantages {
        margin: -125px 0 25px;
      }
    }

    .label {
      width: 305px;
      padding: 20px;

      img {
        height: 40px;
        width: 40px;
      }

      .text {
        margin-left: 20px;
        br {display: initial;}
      }
    }
  }
}
