.JourneysList {
  width: 100%;
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  flex: 1;

  &.loading,
  &.noResults {
    display: flex;
    justify-content: center;
  }

  .noResults {
    text-align: center;
    font: 700 20px/22px $travelPlanerTitleFont;
  }

  @media screen and (min-width: 768px) {
    row-gap: 48px;
  }
}