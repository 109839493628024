.Footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  padding: 15px;
  background: black;
  margin-top: auto;

  .footer-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    gap: 15px;

    .footer-logo {
      width: 100%;
      max-width: 130px;
      margin: 10px;

      svg {
        width: 100%;
      }
      .col0, .col1, .col2 {
        fill: $white;
      }
    }

    .links {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 10px;

      .link {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        font-size: 12px;
        font-family: $mainFont;
        font-weight: 600;
        color: $white;
        text-decoration: none;
        text-transform: uppercase;
        text-align: center;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .social-images-row {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap; /* ensures all items stay in a single row */
      margin-top: 30px; /* add space above the social images row */
    }

    .social-image {
      margin-right: 10px; /* add space between social images */
      margin-left: 10px; /* add space between social images */

    }

    .social-medias {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 15px;
      margin-top: 15px;

      span {
        font-size: 12px;
        font-family: $mainFont;
        font-weight: 600;
        color: $white;
      }

      .medias {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;

        .media {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          font-size: 12px;
          font-family: $mainFont;
          font-weight: 600;
          color: $white;
          text-decoration: none;
          text-transform: uppercase;
        }
      }
    }
  }


  .separator {
    display: none;
    color: $white;
    width: 100%;
    height: 1px;
    opacity: 0.15;
  }

  .footer-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    gap: 5px;

    .link {
      font-size: 10px;
      font-family: $mainFont;
      font-weight: 350;
      color: $white;
      text-decoration: none;
      text-align: center;
      cursor: default;

      &.url {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    gap: 15px;

    .footer-content {
      flex-direction: row;
      justify-content: space-around;
      padding: 40px 80px;
    }
  }

  @media screen and (min-width: 768px) {
    padding: 15px 80px 0 80px;

    .footer-bottom {
      padding: 24px 48px;
      position: relative;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 15px;

      &:before {
        position: absolute;
        top: 0;
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        opacity: 0.15;
        background: $white;
      }

      .dot {
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background: $white;

      }
    }
  }
}



.ModernFooter {
  padding: 40px 0 20px 0;
  background: white;

  .main-footer, .bottom-footer {
    padding: 0 2rem;
  }

  .help-section, .pro-section, .lang-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  /* MAIN FOOTER */

  /*** CHARENTES CSS ***/

  font-weight: 500;
  color: #21212B;
  font-size: .9rem;
  span {
    text-align: left;
    font-weight: 500;
    font-size: 1.1em;
  }

  a{
    width: fit-content;
    margin: 5px 0;
  }

  @media (min-width: 768px) {
    a{
      margin: 0;
    }
  }

  a, button{
    font-size: .9rem;
  }



  /* STYLING */

  .main-footer {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
    margin-bottom: 30px;

    @media (min-width: 768px) {
      // grid-template-columns: 1fr 2fr 1fr 1fr;
      grid-template-columns: 1fr 2fr 1fr;
      align-items: start;
    }
  }

  .logo-section {

    display: flex;
    justify-content: space-between;

    @media (min-width: 768px) {
      flex-direction: column;
    }

    a img{
      width: 150px;
      height: auto;
    }

    .social-links {
      display: flex;
      gap: 15px;
      margin-top: 20px;
    }

    .social-link img {
      width: 24px;
      height: 24px;
    }
  }

  .help-section {

    .nav-links {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: center;
    }

    .nav-link {
      text-decoration: none;
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .lang-section, .help-section {

    @media (min-width: 768px) {
      justify-self: center;
    }
  }



  .lang-section {
    display:none;
  }

  .pro-section {

    @media (min-width: 768px) {
      justify-self: end;
    }

    .pro-access {
      display: flex;
      flex-direction: column;
      gap: 10px;

      h3 {
        font-size: 1em;
        margin-bottom: 10px;
      }

      .pro-link {
        text-decoration: none;
        color: inherit;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  /*** CHARENTES CSS ***/
  a{
    font-weight: 700;
  }


  /* BOTTOM FOOTER */

  .bottom-footer {


    /*** CHARENTES CSS ***/

    font-weight: 500;
    color: #21212B;
    font-size: 0.9em;

    a{
      font-weight: 500;
    }

    /*** STYLING ***/

    padding-top: 20px;
    border-top: 1px solid #eee;
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;

    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr;
      align-items: center;
    }

    .logos {
      display: flex;
      gap: 10px;
      justify-content: center;

      img {
        width: 85px;
        height: 20px;
      }
    }

    .legal-links {
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: end;
    }

    .legal-link {
      text-decoration: none;
      color: inherit;
      font-size: 0.9em;

      &:hover {
        text-decoration: underline;
      }
    }

    .separator {
      font-size: 0.8em;
      color: #666;
    }
  }
}
