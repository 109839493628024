.Journey {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;

  h4 {
    font: 500 16px/22px $travelPlanerTitleFont;
  }

  .imageWrapper {
    flex: 1;
    aspect-ratio: 258/160;
    position: relative;
    border-radius: 6px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .destination {
      position: absolute;
      left: 10px;
      top: 10px;
      border-radius: 100px;
      padding: 6px 9px;
      font: 700 12px/14px $travelPlanerTitleFont;
      color: $green;
      background: rgba(255, 255, 255, 0.8);
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .right {
      display: flex;
      align-items: center;
      gap: 9px;
      font: 400 12px/14px $travelPlanerTitleFont;

      svg {
        width: 14px;
        height: 14px;
      }

      .tripLength {
        display: flex;
        align-items: center;
        gap: 6px;

        svg {
          path {
            fill: $black;
          }
        }
      }

      .likes {
        display: flex;
        align-items: center;
        gap: 3px;
        color: $gray700;
        font: 400 12px/14px $travelPlanerTitleFont;

        &.liked {
          svg {
            path {
              fill: $black;
            }
          }
        }
      }
    }
  }

  .line {
    width: 100%;
    height: 1px;
    background: $gray300;
  }

  @media screen and (min-width: 768px) {
    margin-bottom: 0;
  }
}