.travel-day {
    .travel-day-header {
        display: flex;
        align-items: center;
        gap: 12px;
        
        h3 {
            margin: 0;
        }
        
        .accessibility-icons {
            margin-left: auto; // Pour pousser les icônes à droite
        }
    }
}

.dayHeader {
    .title {
        display: flex;
        align-items: center;
        gap: 12px;

        .accessibility-icons {
            margin-left: 12px;
            display: flex;
            align-items: center;
        }
    }
} 

