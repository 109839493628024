@import './AutoCompleteCity';
@import './TripLengthSelector';

.ComplexSearch {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 12px;
  padding: 24px;
  background: $green-light;
  border-radius: 5px;

  .AutoCompleteCity {
    flex: 1;
  }

  .searchButton,
  .goToGuide {
    width: 100%;
    padding: 14px 20px;
    font: 500 14px/16px $travelPlanerTitleFont;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    background: $green;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: all 0.2s ease-in-out;
  }

  .searchButton {
    &:disabled {
      background: $gray100;
      color: $gray300;
      pointer-events: none;
    }
  }

  .goToGuide {
    gap: 8px;
    padding: 10px 20px;

    svg {
      width: 24px;
      height: 24px;

      path {
        fill: $white;
      }
    }
  }

  h3 {
    margin: auto 12px auto 12px;
  }

  @media screen and (min-width: 768px) {
    padding: 48px;
    flex-direction: row;

    .TripLengthSelector {
      max-width: 242px;
    }

    .searchButton,
    .goToGuide {
      max-width: fit-content;
    }
  }
}
