.Hero {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  text-align: center;

  h2 {
    font: 700 28px/32px $travelPlanerTitleFont;
  }

  h3 {
    font: 500 16px/18px $travelPlanerTitleFont;
    max-width: 750px;
  }

  @media screen and (min-width: 768px) {
    padding: 24px 0;
    gap: 24px;

    h2 {
      font-size: 55px;
      line-height: 63px;
    }

    h3 {
      font-size: 24px;
      line-height: 28px;
    }
  }
}