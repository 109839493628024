.NewsletterPage {
  $mobilePadding: 0 20px;
  $desktopPadding: 10px 0 0 0;

  flex: 1;
  width: 100%;
  padding: $mobilePadding;

  @media screen and (min-width: 768px) {
    padding: $desktopPadding;
  }
}