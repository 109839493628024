@use "sass:map";

.AutoCompleteCity {
  width: 100%;
  position: relative;

  .inputBlock {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid $gray100;
    border-radius: 5px;
    background: $white;
    overflow: hidden;

    svg {
      position: absolute;
      top: 50%;
      left: 24px;
      translate: 0 -50%;
      width: 12px;
      height: auto;
    }

    input {
      font: 500 12px/1 $travelPlanerTitleFont;
      padding: 18px 24px 18px 48px;
      flex: 1;
      min-width: 0;
      text-overflow: ellipsis;
    }
  }

  .predictions {
    z-index: 100;
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    width: 100%;
    background: $white;
    border-radius: 5px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    &.active {
      border: 1px solid $gray100;
    }

    button {
      width: 100%;
      padding: 8px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      opacity: 0.7;
      text-align: left;
      font: 500 12px/1 $travelPlanerTitleFont;
      transition: opacity 0.2s;

      &:hover,
      &:focus {
        opacity: 1;
        background: $gray100;

        svg path {
          fill: $pro;
        }
      }

      .tag {
        display: flex;
        align-items: center;
        gap: 4px;
        background: red;
        border-radius: 30px;
        padding: 4px 8px;
        font-weight: bold;
        font-size: 12px;

        svg {
          min-height: 12px;
          min-width: 12px;
        }

        @each $type, $content in $searchColors {
          &.#{$type} {
            background: map.get($content, background);
            color: map.get($content, color);

            svg path {
              fill: map.get($content, color);
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
  }
}
