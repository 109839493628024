.numberInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px;
  border-radius: 30px;
  background: $gray100;
  width: 100%;

  button {
    background: var(--travelPlanerPrimaryColor, $pro);
    height: 30px;
    width: 30px;
    color: $white;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;

    &:disabled {
      opacity: 0.5;
    }
  }
}
