.imagePlaceholder {
  background: linear-gradient(90.03deg, rgba(255, 255, 255, 0) 0.02%, rgba(255, 255, 255, 0.6) 34.76%, rgba(255, 255, 255, 0.250316) 35.6%, rgba(255, 255, 255, 0) 46.85%), linear-gradient(0deg, $gray500, $gray500), #F5F5F5;
  background-size: 400% 400%;
  animation: gradient 3s ease-in-out infinite;
  height: 100%;
  width: 100%;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.DesktopImage{
  display:none;
}

@media screen and (min-width: 768px) {

  .assignedDisabilities {
    img {
      width: 180px;
      margin: 5px 0;
    }
  }
  .DesktopImage {
    display: flex;
  }
  .MobileImage{
    display: none;
  }
}

@media screen and (max-width: 768px) {

  .assignedDisabilities {
    img {
      width: 100px;
      margin: 5px 0;
    }
  }
}

