@import './components/CategoriesFilters';
@import './components/ComplexSearch';
@import './components/FiltersAndSort';
@import './components/Hero';
@import './components/Journey';
@import './components/JourneysList';

.TravelPlanerHub {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  flex: 1;
  padding: 48px 24px;
  display: flex;
  flex-direction: column;
  gap: 48px;

  @media screen and (min-width: 768px) {
    padding: 48px;
  }
}